import { useState, useEffect } from "react";
import useEventListener from "./useEventListener";

const useMediaQuery = (mediaQuery: any) => {
  const [isMatch, setIsMatch] = useState(false);
  const [mediaQueryList, setMediaQueryList] = useState(null);

  useEffect(() => {
    const list = window.matchMedia(mediaQuery);
    setMediaQueryList(list);
    setIsMatch(list.matches);
  }, [mediaQuery]);

  useEventListener("change", (e: any) => setIsMatch(e.matches), mediaQueryList);

  return isMatch;
};

export default useMediaQuery;
