import axios from "axios";

export const http = axios.create({
  baseURL: process.env.REACT_APP_WEBHOOKS_BASE_URL,
  headers: {
    "Content-type": "application/json",
  },
});

export const youtube = axios.create({
  baseURL: "https://www.googleapis.com/youtube/v3/",
  params: {
    part: "snippet",
    maxResults: 1,
    key: process.env.REACT_APP_GOOGLE_API_KEY,
  },
});
