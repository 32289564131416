import React from "react";
// Styles
import { Wrapper, Content, Text, HamburgerMenu } from "./Header.styles";
// Icons
import { HiOutlineMenu, HiX, HiMoon, HiSun } from "react-icons/hi";
// Routes
import { Link, useNavigate } from "react-router-dom";
// Mui
import Button from "@mui/material/Button";

const Header = ({
  hamburgerOpen,
  handleToggle,
  handleToggleDarkMode,
  darkMode,
  handleCloseHamburger,
  windowSize,
  app,
  handleLoggedinUser,
  loggedinUser,
}: {
  hamburgerOpen: boolean;
  handleToggle: any;
  handleToggleDarkMode: any;
  darkMode: boolean;
  handleCloseHamburger: any;
  windowSize: any;
  app: any;
  handleLoggedinUser: any;
  loggedinUser: any;
}) => {
  const logOut = async (app: any) => {
    await app.currentUser.logOut();
    handleLoggedinUser(null);
    handleCloseHamburger();
  };

  const navigate = useNavigate();

  const checkLogin = () => {
    try {
      var user = loggedinUser.currentUser;
      return true;
    } catch {
      return false;
    }
  };

  return (
    <Wrapper darkMode={darkMode}>
      <Content hamburgerOpen={hamburgerOpen}>
        <Text>
          <Link
            to="/"
            style={{ textDecoration: "none" }}
            onClick={handleCloseHamburger}
          >
            <h2>Hardstyle Family</h2>
          </Link>
        </Text>
        {hamburgerOpen ? (
          <HiX
            style={
              darkMode
                ? { margin: "10px 0 0 0", color: "var(--white)" }
                : { margin: "10px 0 0 0", color: "var(--black)" }
            }
            size={windowSize.width < 1600 ? "70" : "40"}
            onClick={handleToggle}
          />
        ) : (
          <HiOutlineMenu
            style={
              darkMode
                ? { margin: "10px 0 0 0", color: "var(--white)" }
                : { margin: "10px 0 0 0", color: "var(--black)" }
            }
            size={windowSize.width < 1600 ? "70" : "40"}
            onClick={handleToggle}
          />
        )}
        {hamburgerOpen ? (
          <HamburgerMenu darkMode={darkMode}>
            <ul>
              {darkMode ? (
                <HiSun
                  onClick={handleToggleDarkMode}
                  style={
                    windowSize.width < 1600
                      ? {
                          color: "var(--white)",
                          float: "right",
                          margin: "30px 0 0",
                        }
                      : { color: "var(--white)", float: "right" }
                  }
                  size={windowSize.width < 1600 ? "70" : "40"}
                />
              ) : (
                <HiMoon
                  onClick={handleToggleDarkMode}
                  style={
                    windowSize.width < 1600
                      ? {
                          color: "var(--black)",
                          float: "right",
                          margin: "30px 0 0",
                        }
                      : { color: "var(--black)", float: "right" }
                  }
                  size={windowSize.width < 1600 ? "70" : "40"}
                />
              )}
            </ul>

            {checkLogin() ? (
              <ul>
                <li>
                  <p>You are logged in as: {app.currentUser.profile.email}</p>
                </li>
                <li>
                  <Button
                    className="ButtonComponent"
                    variant="contained"
                    color="error"
                    onClick={() => logOut(app)}
                  >
                    <strong>
                      <p>Log out</p>
                    </strong>
                  </Button>
                </li>
                <li>
                  <Button
                    className="ButtonComponent"
                    variant="contained"
                    onClick={() => {
                      navigate("/sendmail");
                      handleToggle();
                    }}
                  >
                    <strong>
                      <p>Reset password</p>
                    </strong>
                  </Button>
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  <Button
                    className="ButtonComponent"
                    variant="contained"
                    color="success"
                    onClick={() => {
                      navigate("/login");
                      handleToggle();
                    }}
                  >
                    <strong>Log In</strong>
                  </Button>
                </li>
                <li>
                  <Button
                    className="ButtonComponent"
                    variant="contained"
                    onClick={() => {
                      navigate("/signup");
                      handleToggle();
                    }}
                  >
                    <strong>Create user</strong>
                  </Button>
                </li>
              </ul>
            )}
          </HamburgerMenu>
        ) : (
          <></>
        )}
      </Content>
    </Wrapper>
  );
};

export default Header;
