import React from "react";
import { useState } from "react";
import * as Realm from "realm-web";
import { useForm } from "react-hook-form";
// Styles
import { Wrapper } from "./ResetPassword.styles";

const ResetPassword = ({ darkMode }: { darkMode: boolean }) => {
  const [password, setPassword] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    ResetPasswordFunction(data);
    setPassword(true);
  };

  const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });

  const ResetPasswordFunction = async (data: any) => {
    let token = new URLSearchParams(window.location.search).get("token");
    let tokenId = new URLSearchParams(window.location.search).get("tokenId");
    await app.emailPasswordAuth.resetPassword({
      password: data.password,
      token,
      tokenId,
    });
  };

  return (
    <Wrapper darkMode={darkMode}>
      <div>
        {password ? (
          <h2>Password is reset</h2>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <p>New password</p>
            <input
              type="password"
              {...register("password", { required: true })}
            />
            <input type="submit" value="Set new password" />
          </form>
        )}
      </div>
    </Wrapper>
  );
};

export default ResetPassword;
