import useMediaQuery from "./useMediaQuery";
import { useLocalStorage } from "./useStorage";

const useDarkMode = () => {
  const [darkMode, setDarkMode] = useLocalStorage("useDarkMode", "useDarkMode");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const enabled: boolean = darkMode ?? prefersDarkMode;

  return [enabled, setDarkMode];
};

export default useDarkMode;
