import React, { useEffect, useState } from "react";
// Routing
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// Components
import Header from "./components/Header/index";
import Login from "./components/Login";
import Home from "./components/Home";
import Signup from "./components/Signup";
import Footer from "./components/Footer/index";
import ConfirmEmail from "./components/ConfirmEmail/index";
import ResetPasswordSendMail from "./components/ResetPassword/sendmail";
import ResetPassword from "./components/ResetPassword/resetpassword";
import EventDetails from "./components/EventDetails/index";
// Styling
import { Body, Wrapper } from "./App.styles";
import { GlobalStyle } from "./GlobalStyle";
// Hooks
import useWindowSize from "./hooks/useWindowSize";
import useDarkMode from "./hooks/useDarkMode";
import useMediaQuery from "./hooks/useMediaQuery";

// Api
import * as Realm from "realm-web";

function App() {
  const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [darkMode, setDarkMode] = useDarkMode();
  const windowSize = useWindowSize();
  const [loggedinUser, setLoggedinUser] = useState(app.currentUser);
  const [currentEvent, setCurrentEvent] = useState();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const handleToggle = () => {
    setHamburgerOpen((prev) => !prev);
  };

  const handleCloseHamburger = () => {
    setHamburgerOpen(false);
  };

  const handleToggleDarkMode = () => {
    setDarkMode(!darkMode);
    handleToggle();
  };

  const handleLoggedinUser = (user: Realm.User) => {
    setLoggedinUser(user);
  };

  const handleCurrentEvent = (event: any) => {
    setCurrentEvent(event);
  };

  useEffect(() => {
    console.log("rerendered");
    if (prefersDarkMode) {
      setDarkMode(true);
    }
  }, []);

  return (
    <Router>
      <Wrapper darkMode={darkMode}>
        <Header
          handleToggle={handleToggle}
          hamburgerOpen={hamburgerOpen}
          handleToggleDarkMode={handleToggleDarkMode}
          darkMode={darkMode}
          handleCloseHamburger={handleCloseHamburger}
          windowSize={windowSize}
          app={app}
          handleLoggedinUser={handleLoggedinUser}
          loggedinUser={loggedinUser}
        />
        <Body hamburgerOpen={hamburgerOpen}>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  darkMode={darkMode}
                  loggedinUser={loggedinUser}
                  app={app}
                  handleCurrentEvent={handleCurrentEvent}
                />
              }
            ></Route>
            <Route
              path="/event/:id"
              element={
                <EventDetails
                  darkMode={darkMode}
                  event={currentEvent}
                  handleCurrentEvent={handleCurrentEvent}
                />
              }
            ></Route>

            <Route
              path="/login"
              element={
                <Login
                  handleLoggedinUser={handleLoggedinUser}
                  loggedinUser={loggedinUser}
                  darkMode={darkMode}
                />
              }
            />
            <Route
              path="/signup"
              element={
                <Signup
                  darkMode={darkMode}
                  handleLoggedinUser={handleLoggedinUser}
                  loggedinUser={loggedinUser}
                />
              }
            />
            <Route
              path="/sendmail"
              element={<ResetPasswordSendMail darkMode={darkMode} />}
            />
            <Route
              path="/resetpassword"
              element={<ResetPassword darkMode={darkMode} />}
            />
            <Route path="/confirmEmail" element={<ConfirmEmail />} />
          </Routes>
        </Body>
        <Footer darkMode={darkMode} />
        <GlobalStyle darkMode={darkMode} />
      </Wrapper>
    </Router>
  );
}

export default App;
