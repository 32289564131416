import React from "react";
import { useState } from "react";
import * as Realm from "realm-web";
import { useForm } from "react-hook-form";
// Styles
import { Wrapper } from "./ResetPassword.styles";

const ResetPasswordSendMail = ({ darkMode }: { darkMode: boolean }) => {
  const [sentmail, setSentMail] = useState(false);

  const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    ResetPasswordFunction(data);
    setSentMail(true);
  };

  const ResetPasswordFunction = async (data: any) => {
    await app.emailPasswordAuth.sendResetPasswordEmail(data.email);
  };

  return (
    <Wrapper darkMode={darkMode}>
      {sentmail ? (
        <h2>Check your mail for instructions</h2>
      ) : (
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <p>Email</p>
            <input {...register("email", { required: true })} />
            <input type="submit" value="Send a reset password mail" />
          </form>
        </div>
      )}
    </Wrapper>
  );
};

export default ResetPasswordSendMail;
