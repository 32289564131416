import styled from "styled-components";

export const Wrapper = styled("div")<{ darkMode: boolean }>`
  background: ${(props) =>
    props.darkMode
      ? "radial-gradient(circle, rgba(67,67,68,0.8813900560224089) 5%, rgba(67,67,68,1) 25%, rgba(67,67,68,1) 100%)"
      : "radial-gradient(ellipse at center,rgba(255, 255, 255, 1) 0%,rgba(229, 229, 229, 1) 100%)"};
  margin: 0 30px;
  background-size: 100%, cover;
  background-position: center;
  border-radius: 30px;
  animation: animateLandingCard 2s;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
  height: 500px;

  @keyframes animateLandingCard {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  div {
    display: block;
    height: 500px;
    position: relative;
  }

  form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }

  p {
    display: block;
    text-align: left;
    font-size: 25px;
  }

  input {
    display: block;
    width: 100%;
    height: 50px;
    margin: 0.5vh auto 0 auto;
    border: 0;
    border-radius: 30px;
    font-size: 20px;
    text-align: center;
    background-color: lightgrey;
  }

  a {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
  }

  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
