import React from "react";
// Components
import LandingCard from "./LandingCard/index";

const Home = ({
  darkMode,
  loggedinUser,
  app,
  handleCurrentEvent,
}: {
  darkMode: boolean;
  loggedinUser: any;
  app: any;
  handleCurrentEvent: any;
}) => {
  return (
    <>
      <LandingCard
        darkMode={darkMode}
        loggedinUser={loggedinUser}
        app={app}
        handleCurrentEvent={handleCurrentEvent}
      />
    </>
  );
};

export default Home;
