import React, { useState, useEffect } from "react";
import * as Realm from "realm-web";
// Routes
import { useNavigate } from "react-router-dom";
// Styles
import {
  Wrapper,
  Content,
  Image,
  Left,
  Right,
  List,
  ListItem,
  BigWrapper,
} from "./LandingCard.styles";
// Api service
import EventDataService from "../../services/event";

import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MdInfoOutline } from "react-icons/md";
import ReactLoading from "react-loading";
import { any } from "prop-types";

const LandingCard = ({
  darkMode,
  loggedinUser,
  app,
  handleCurrentEvent,
}: {
  darkMode: boolean;
  loggedinUser: any;
  app: any;
  handleCurrentEvent: any;
}) => {
  const [events, setEvents] = useState([]);
  const [eventsAttending, setEventsAttending] = useState([]);
  const [eventsInterested, setEventsInterested] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState(null);
  const [activeEvent, setActiveEvent] = useState<any | boolean | boolean>({
    event_id: null,
    attending: false,
    interested: false,
  });
  const [pageloading, setPageLoading] = useState(true);

  const navigate = useNavigate();

  const retrieveEvents = async () => {
    try {
      const eventsResponse = await EventDataService.getAll();
      var events = eventsResponse.data.events.sort(
        (a: any, b: any) =>
          new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime()
      );
      setEvents(events);
    } catch (e) {
      console.log(e);
    }
  };

  const retrieveEventsAttending = async () => {
    try {
      const attending = await EventDataService.getAllAttending();
      setEventsAttending(attending.data);
    } catch (e) {
      console.log(e);
    }
  };

  const retrieveEventsInterested = async () => {
    try {
      const interested = await EventDataService.getAllInterested();
      setEventsInterested(interested.data);
    } catch (e) {
      console.log(e);
    }
  };
  const checkLogin = () => {
    try {
      var user = loggedinUser.currentUser;
      return true;
    } catch {
      return false;
    }
  };

  const theme = createTheme({
    palette: {
      action: {
        disabledBackground: "#bdbdbd",
        disabled: "#ffffff",
      },
    },
  });

  useEffect(() => {
    const getItems = async () => {
      await retrieveEvents();
      await retrieveEventsAttending();
      await retrieveEventsInterested();
      if (checkLogin()) {
        app.currentUser.refreshCustomData();
      }
      setPageLoading(false);
    };
    getItems();
  }, []);

  // Handle action on going button
  const HandleGoing = async (props: any) => {
    setLoadingType("going");
    setLoading(true);
    setActiveEvent(props);
    const response = await EventDataService.getEventUserRelations(
      props.user_id,
      props.event_id
    );
    if (response.data.$undefined) {
      await EventDataService.createEventUserRelations({
        user_id: props.user_id,
        event_id: props.event_id,
        event_name: props.event_name,
        attending: props.attending,
        firstname: app.currentUser.customData.firstname,
        lastname: app.currentUser.customData.lastname,
      });
      retrieveEventsAttending();
      retrieveEventsInterested();
    } else {
      await EventDataService.updateEventUserRelation({
        user_id: props.user_id,
        event_id: props.event_id,
        attending: props.attending,
        firstname: app.currentUser.customData.firstname,
        lastname: app.currentUser.customData.lastname,
      });
      retrieveEventsAttending();
      retrieveEventsInterested();
    }
    setLoading(false);
    setActiveEvent({ event_id: null, attending: false });
  };

  // Handle action on going button
  const HandleInterested = async (props: any) => {
    setLoadingType("interested");
    setLoading(true);
    console.log("Setting active event");
    setActiveEvent(props);
    console.log("Gets Event User Relations");
    const response = await EventDataService.getEventUserRelations(
      props.user_id,
      props.event_id
    );
    console.log(response);
    if (response.data === undefined) {
      console.log("Post");
      await EventDataService.createEventUserRelations({
        user_id: props.user_id,
        event_id: props.event_id,
        event_name: props.event_name,
        interested: props.interested,
        firstname: app.currentUser.customData.firstname,
        lastname: app.currentUser.customData.lastname,
      });
      console.log("Retrieves after put");
      retrieveEventsInterested();
      retrieveEventsAttending();
    } else {
      if (response.data.interested) {
        props.interested = false;
      }
      console.log("put");
      await EventDataService.updateEventUserRelation({
        user_id: props.user_id,
        event_id: props.event_id,
        interested: props.interested,
        firstname: app.currentUser.customData.firstname,
        lastname: app.currentUser.customData.lastname,
      });
      console.log("Retrieves after put");
      retrieveEventsInterested();
      retrieveEventsAttending();
    }
    console.log("Removing loading");
    setLoading(false);
    setActiveEvent({ event_id: null, interested: true });
  };

  return (
    <>
      {pageloading ? (
        <BigWrapper darkMode={darkMode}>
          <ReactLoading className="bigloading" type="spinningBubbles" />
        </BigWrapper>
      ) : (
        <BigWrapper darkMode={darkMode}>
          {events
            .filter((event) => new Date(event.dateFrom) > new Date())
            .map((event, index) => {
              var dateFrom = new Date(event.dateFrom);
              var dateTo = new Date(event.dateTo);
              var totalAttending = 0;
              var totalInterested = 0;
              var totalNotAttending = 0;
              var UsersAttending;
              var UsersInterested;
              var UsersNotAttending = null;
              try {
                totalAttending = eventsAttending.find(
                  ({ id, attending }) => id === event._id && attending === true
                ).sum.$numberLong;
              } catch {}

              try {
                totalInterested = eventsInterested.find(
                  ({ id, interested }) =>
                    id === event._id && interested === true
                ).sum.$numberLong;
              } catch {}

              try {
                totalNotAttending = eventsAttending.find(
                  ({ id, attending }) => id === event._id && attending === false
                ).sum.$numberLong;
              } catch {}

              try {
                UsersAttending = eventsAttending.find(
                  ({ id, attending }) => id === event._id && attending === true
                ).attendingusers;
              } catch {}

              try {
                UsersInterested = eventsInterested.find(
                  ({ id, interested }) =>
                    id === event._id && interested === true
                ).interestedusers;
              } catch {}

              try {
                UsersNotAttending = eventsAttending.find(
                  ({ id, attending }) => id === event._id && attending === false
                ).attendingusers;
              } catch {}

              return (
                <Wrapper darkMode={darkMode} key={index}>
                  <Content darkMode={darkMode}>
                    <Left>
                      <Image src={event.image} alt={event.name} />
                    </Left>
                    <Right>
                      <List>
                        <ListItem>
                          <h1>{event.name}</h1>
                        </ListItem>
                        <ListItem>
                          <h3>Date</h3>
                          <p>
                            {dateFrom.getDate()}.{dateFrom.getMonth() + 1}.
                            {dateFrom.getFullYear()} - {dateTo.getDate()}.
                            {dateTo.getMonth() + 1}.{dateTo.getFullYear()}
                          </p>
                        </ListItem>
                        <ListItem>
                          <h3>Location</h3>
                          <p>
                            {event.location} | {event.country}
                          </p>
                        </ListItem>
                        <ListItem>
                          <div className="attending">
                            {checkLogin() ? (
                              <Button
                                color="success"
                                variant="contained"
                                onClick={() =>
                                  HandleGoing({
                                    user_id: app.currentUser.id,
                                    event_id: event._id,
                                    event_name: event.name,
                                    attending: true,
                                  })
                                }
                              >
                                going
                              </Button>
                            ) : (
                              <Tooltip
                                enterTouchDelay={0}
                                title="Log in to vote"
                              >
                                <span>
                                  <ThemeProvider theme={theme}>
                                    <Button variant="contained" disabled>
                                      going
                                    </Button>
                                  </ThemeProvider>
                                </span>
                              </Tooltip>
                            )}

                            <Tooltip
                              enterTouchDelay={0}
                              title={
                                UsersAttending
                                  ? UsersAttending.map(
                                      (user: any, index: any) => {
                                        return (
                                          <li key={index}>
                                            {user.firstname} {user.lastname}
                                          </li>
                                        );
                                      }
                                    )
                                  : "No users"
                              }
                            >
                              <Button>
                                <MdInfoOutline />
                                Who is going?
                              </Button>
                            </Tooltip>
                            {activeEvent.event_id === event._id &&
                              loading &&
                              activeEvent.attending &&
                              loadingType === "going" && (
                                <ReactLoading
                                  className="smalloading"
                                  type="spinningBubbles"
                                />
                              )}
                          </div>
                          <p>Total amount going: {totalAttending}</p>
                        </ListItem>
                        <ListItem>
                          <div className="attending">
                            {checkLogin() ? (
                              <Button
                                variant="contained"
                                onClick={() =>
                                  HandleInterested({
                                    user_id: app.currentUser.id,
                                    event_id: event._id,
                                    event_name: event.name,
                                    interested: true,
                                  })
                                }
                              >
                                Interested
                              </Button>
                            ) : (
                              <Tooltip
                                enterTouchDelay={0}
                                title="Log in to vote"
                              >
                                <span>
                                  <ThemeProvider theme={theme}>
                                    <Button variant="contained" disabled>
                                      interested
                                    </Button>
                                  </ThemeProvider>
                                </span>
                              </Tooltip>
                            )}

                            <Tooltip
                              enterTouchDelay={0}
                              title={
                                UsersInterested
                                  ? UsersInterested.map(
                                      (user: any, index: any) => {
                                        return (
                                          <li key={index}>
                                            {user.firstname} {user.lastname}
                                          </li>
                                        );
                                      }
                                    )
                                  : "No users"
                              }
                            >
                              <Button>
                                <MdInfoOutline />
                                Who is interested?
                              </Button>
                            </Tooltip>
                            {activeEvent.event_id === event._id &&
                              loading &&
                              activeEvent.interested &&
                              loadingType === "interested" && (
                                <ReactLoading
                                  className="smalloading"
                                  type="spinningBubbles"
                                />
                              )}
                          </div>
                          <p>Total amount interested: {totalInterested}</p>
                        </ListItem>
                        <ListItem>
                          <div className="attending">
                            {checkLogin() ? (
                              <Button
                                color="error"
                                variant="contained"
                                onClick={() =>
                                  HandleGoing({
                                    user_id: app.currentUser.id,
                                    event_id: event._id,
                                    event_name: event.name,
                                    attending: false,
                                  })
                                }
                              >
                                Not going
                              </Button>
                            ) : (
                              <Tooltip
                                enterTouchDelay={0}
                                title="Log in to vote"
                              >
                                <span>
                                  <ThemeProvider theme={theme}>
                                    <Button variant="contained" disabled>
                                      Not going
                                    </Button>
                                  </ThemeProvider>
                                </span>
                              </Tooltip>
                            )}

                            <Tooltip
                              enterTouchDelay={0}
                              title={
                                UsersNotAttending
                                  ? UsersNotAttending.map(
                                      (user: any, index: any) => {
                                        return (
                                          <li key={index}>
                                            {user.firstname} {user.lastname}
                                          </li>
                                        );
                                      }
                                    )
                                  : "No users"
                              }
                            >
                              <Button>
                                <MdInfoOutline />
                                Who is not going?
                              </Button>
                            </Tooltip>
                            {activeEvent.event_id === event._id &&
                              loading &&
                              !activeEvent.attending &&
                              loadingType === "going" && (
                                <ReactLoading
                                  className="smalloading"
                                  type="spinningBubbles"
                                />
                              )}
                          </div>
                          <p>Total amount not going: {totalNotAttending}</p>
                        </ListItem>
                        <ListItem>
                          <Button
                            onClick={() => {
                              handleCurrentEvent(event);
                              navigate(`/event/${event._id}`);
                            }}
                          >
                            read more
                          </Button>
                        </ListItem>
                      </List>
                    </Right>
                  </Content>
                </Wrapper>
              );
            })}
        </BigWrapper>
      )}
    </>
  );
};

export default LandingCard;
