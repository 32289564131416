import styled from "styled-components";

export const Body = styled("div")<{ hamburgerOpen: boolean }>`
  filter: ${(props) => (props.hamburgerOpen ? "blur(4px)" : "blur(0px)")};
  display: flex;
  justify-content: center;
  align-items: stretch;
  position: relative;
  flex-direction: column;
  margin: 30px 30px;
  min-height: 100%;
  margin-top: 150px;
  width: 90vw;
`;

export const Wrapper = styled("div")<{ darkMode: boolean }>`
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${(props) => (props.darkMode ? "#000000" : "#ffffff")};
`;
