import React, { useState, useEffect, useRef } from "react";
// Styles
import { BigWrapper, Content, Image } from "./EventDetails.styles";
// Api service
import EventDataService from "../../services/event";
// Router
import { useParams } from "react-router-dom";
// Loading
import ReactLoading from "react-loading";
// Youtube search
import { youtube } from "../../http-common";
import SelectInput from "@mui/material/Select/SelectInput";

const EventDetails = ({
  darkMode,
  event,
  handleCurrentEvent,
}: {
  darkMode: boolean;
  event: any;
  handleCurrentEvent: any;
}) => {
  const [pageloading, setPageLoading] = useState(true);
  const { id } = useParams();
  const [videoId, setVideoId] = useState(null);

  const retrieveEventDetails = async () => {
    if (!event) {
      try {
        const currentEvent = await EventDataService.get(id);
        handleCurrentEvent(currentEvent.data);
        return currentEvent.data;
      } catch (e) {
        console.log(e);
      }
    } else {
      return event;
    }
  };

  const retrieveVideo = async (currentEvent: any) => {
    /*
    const response = await youtube.get("/search", {
      params: {
        q: `${currentEvent.name} ${
          new Date().getFullYear() - 1
        } official aftermovie`,
      },
    });
    setVideoId(response.data.items[0].id.videoId);
    */
  };

  useEffect(() => {
    const initialRetrieval = async () => {
      const currentEvent = await retrieveEventDetails();
      setPageLoading(false);
      retrieveVideo(currentEvent);
    };
    initialRetrieval();
  }, []);

  return (
    <BigWrapper darkMode={darkMode}>
      {pageloading ? (
        <ReactLoading className="bigloading" type="spinningBubbles" />
      ) : (
        <Content darkMode={darkMode}>
          <h1>{event.name}</h1>
          <Image src={event.image} alt={event.name} />
          <h3>Video</h3>
          <div className="video-wrap">
            <div className="video-container">
              <iframe
                title="yt"
                id="ytplayer"
                width="80%"
                height="450"
                src={`https://www.youtube.com/embed/${event.ytVideoId}?autoplay=1&mute=1`}
                frameBorder="0"
              ></iframe>
            </div>
          </div>
          <h3>Location</h3>
          <iframe
            title="gmaps"
            width="80%"
            height="450"
            style={{ border: 0 }}
            loading="lazy"
            allowFullScreen
            src={`https://www.google.com/maps/embed/v1/place?q=${event.location}&zoom=5&key=AIzaSyDb9TaZ-FyusSDSlM5zYhu1NStzNqN6Iu8`}
          />
        </Content>
      )}
    </BigWrapper>
  );
};

export default EventDetails;
