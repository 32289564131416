import { http } from "../http-common";

class EventDataService {
  getAll(page = 0) {
    return http.get(`/events?page=${page}`);
  }

  getAllAttending() {
    return http.get("/geteventsattending");
  }

  getAllInterested() {
    return http.get("/geteventsinterested");
  }

  get(id: any) {
    return http.get(`/getevent?id=${id}`);
  }

  find(query: any, by = "name", page = 0) {
    return http.get(`/events?${by}=${query}&page=${page}`);
  }

  createReaction(data: any) {
    return http.post("/reaction-new", data);
  }

  updateReaction(data: any) {
    return http.put("/reaction-edit", data);
  }

  getEventUserRelations(userid: any, eventid: any) {
    return http.get(
      `/geteventuserrelation?user_id=${userid}&event_id=${eventid}`
    );
  }

  getEventUserRelationSum(eventid: any, attending: any) {
    return http.get(
      `/geteventuserrelationsum?event_id${eventid}&attending=${attending}`
    );
  }

  createEventUserRelations(data: any) {
    return http.post("/posteventuserrelation", data);
  }

  updateEventUserRelation(data: any) {
    return http.put("/puteventuserrelation", data);
  }

  createUserCustomData(data: any) {
    return http.post("/postusercustomdata", data);
  }

  deleteReaction(id: any, userId: any) {
    return http.delete(`/reaction-delete?id=${id}`, {
      data: { user_id: userId },
    });
  }

  getTypes() {
    return http.get(`/types-getdistinct`);
  }
}

export default new EventDataService();
