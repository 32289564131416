import React from "react";
import { useEffect } from "react";
import * as Realm from "realm-web";

const ConfirmEmail = () => {
  const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });

  const ConfirmEmailFunction = async () => {
    let token = new URLSearchParams(window.location.search).get("token");
    let tokenId = new URLSearchParams(window.location.search).get("tokenId");
    await app.emailPasswordAuth.confirmUser({ token, tokenId });
  };

  useEffect(() => {
    ConfirmEmailFunction();
  });

  return (
    <div>
      <p>You have confirmed your email, and you can now login.</p>
    </div>
  );
};

export default ConfirmEmail;
