import React from "react";
import { useState } from "react";
import * as Realm from "realm-web";
import { useForm } from "react-hook-form";
// Styles
import { Wrapper } from "./Signup.styles";
// Api
import EventDataService from "../../services/event";
// Loading
import ReactLoading from "react-loading";

//const assert = require("assert");

const Signup = ({
  darkMode,
  handleLoggedinUser,
  loggedinUser,
}: {
  darkMode: boolean;
  handleLoggedinUser: any;
  loggedinUser: any;
}) => {
  const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    setLoading(true);
    // Register user
    await registerEmailPassword(data.email, data.password);
    // Login to allocate an Id
    const user = await loginEmailPassword(data.email, data.password);
    await EventDataService.createUserCustomData({ ...data, userId: user.id });
    await app.currentUser.refreshCustomData();
    setLoading(false);
  };

  const checkLogin = () => {
    try {
      var user = loggedinUser.currentUser;
      return true;
    } catch {
      return false;
    }
  };

  const registerEmailPassword = async (email: string, password: string) => {
    await app.emailPasswordAuth.registerUser({ email, password });
  };

  const loginEmailPassword = async (email: string, password: string) => {
    // Create an anonymous credential
    const credentialsEmailPassword = Realm.Credentials.emailPassword(
      email,
      password
    );
    try {
      // Authenticate the user
      const user = await app.logIn(credentialsEmailPassword);
      // `App.currentUser` updates to match the logged in user
      //assert(user.id === app.currentUser.id);
      handleLoggedinUser(user);
      await app.currentUser.refreshCustomData();
      return user;
    } catch (err) {
      console.error("Failed to log in", err);
    }
  };

  return (
    <Wrapper darkMode={darkMode}>
      {checkLogin() ? (
        <h2>
          <>
            You are logged in, {app.currentUser.customData.firstname}{" "}
            {app.currentUser.customData.lastname}
          </>
        </h2>
      ) : (
        <div>
          {loading ? (
            <ReactLoading className="loading" type="spinningBubbles" />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* register your input into the hook by invoking the "register" function */}
              <p>Email</p>
              <input {...register("email", { required: true })} />

              {/* include validation with required or other standard HTML validation rules */}
              <p>Password</p>
              <input
                type="password"
                {...register("password", {
                  required: true,
                  minLength: {
                    value: 7,
                    message:
                      "Too short password. Please use more than 7 characters.",
                  },
                })}
              />
              <p>Firstname</p>
              <input {...register("firstname", { required: true })} />
              <p>Lastname</p>
              <input {...register("lastname", { required: true })} />
              {/* errors will return when field validation fails  */}
              <p>{errors.password?.message}</p>
              <input
                className="inputSubmit"
                type="submit"
                value="Create user"
              />
            </form>
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default Signup;
