import styled from "styled-components";

export const Wrapper = styled("div")<{ darkMode: boolean }>`
  background-color: ${(props) =>
    props.darkMode ? "var(--black)" : "var(--white)"};
  //bottom: 0;
  //position: fixed;
  width: 100%;
  text-align: center;
  //z-index: 2;
  //height: fit-content;
  border-radius: 30px;
  flex: 0 0 50px;
  margin-top: auto;
`;

export const Content = styled("div")<{ darkMode: boolean }>`
  color: var(--black);

  a {
    color: ${(props) => (props.darkMode ? "red" : "blue")};
  }
`;
