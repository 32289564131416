import styled from "styled-components";

export const BigWrapper = styled("div")<{ darkMode: boolean }>`
  background-color: ${(props) =>
    props.darkMode ? "var(--black)" : "var(--white)"};
  bottom: 0;
  position: relative;
  width: 100%;
  text-align: center;
  z-index: 2;
  height: fit-content;
  border-radius: 30px;
`;

export const Content = styled("div")<{ darkMode: boolean }>`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  p {
    color: ${(props) => (props.darkMode ? "var(--white)" : "var(--black)")};
  }

  .bigloading {
    svg {
      position: absolute;
      top: 200px;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 30px;
      height: 200px;
      width: 200px;
      fill: ${(props) => (props.darkMode ? "#ffffff" : "#000000")};
    }
  }

  .video-container {
    position: relative;
    overflow: hidden;

    height: 0;
    padding-bottom: 56.25%;
  }

  .video-container iframe,
  .video-container embed {
    position: absolute;
    top: 0;

    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    height: 100%;
    max-width: 100%;
  }

  .video-wrap {
    max-width: 100%;
    width: 80%;
  }
`;

export const Image = styled.img`
  border-radius: 15px;
  margin: 30px;
  max-width: 80%;
`;

export const List = styled.ul`
  list-style-type: none;
`;

export const ListItem = styled.li`
  margin: 40px 32px 60px 0;

  a {
    color: inherit;
  }

  svg {
    margin: 0 10px 0 0;
  }

  .attending {
    display: flex;
  }
`;
