import React from "react";
import * as Realm from "realm-web";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useState } from "react";
// Styles
import { Wrapper } from "./Login.styles";
// Mui
import Button from "@mui/material/Button";
// Loading
import ReactLoading from "react-loading";

//const assert = require("assert");

const Login = ({
  handleLoggedinUser,
  loggedinUser,
  darkMode,
}: {
  handleLoggedinUser: any;
  loggedinUser: any;
  darkMode: boolean;
}) => {
  const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });

  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    setLoading(true);
    await loginEmailPassword(data.email, data.password);
    setLoading(false);
  };

  const checkLogin = () => {
    try {
      var user = loggedinUser.currentUser;
      return true;
    } catch {
      return false;
    }
  };

  const loginEmailPassword = async (email: string, password: string) => {
    // Create an anonymous credential
    const credentialsEmailPassword = Realm.Credentials.emailPassword(
      email,
      password
    );
    try {
      // Authenticate the user
      const user = await app.logIn(credentialsEmailPassword);
      // `App.currentUser` updates to match the logged in user
      //assert(user.id === app.currentUser.id);
      handleLoggedinUser(user);
      await app.currentUser.refreshCustomData();
      const customData = app.currentUser.customData;
      return user;
    } catch (err) {
      console.error("Failed to log in", err);
      setHasError(true);
    }
  };
  /*
  const registerEmailPassword = async (email, password) => {
    await app.emailPasswordAuth.registerUser(email, password);
  };
  */
  return (
    <Wrapper darkMode={darkMode}>
      {checkLogin() ? (
        <h2>
          <>
            You are logged in, {app.currentUser.customData.firstname}{" "}
            {app.currentUser.customData.lastname}
          </>
        </h2>
      ) : (
        <div className="content">
          {loading ? (
            <ReactLoading className="loading" type="spinningBubbles" />
          ) : (
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* register your input into the hook by invoking the "register" function */}
                <p>Email</p>
                <input {...register("email", { required: true })} />

                {/* include validation with required or other standard HTML validation rules */}
                <p>Password</p>
                <input
                  type="password"
                  {...register("password", { required: true })}
                />
                {/* errors will return when field validation fails  */}
                {errors.exampleRequired && <span>This field is required</span>}
                <input type="submit" value="Log in" />
              </form>
              <div className="nouser">
                {hasError && <span>Something went wrong</span>}
                <Button className="ButtonComponent1" variant="contained">
                  <Link className="link" to={"/sendmail"}>
                    <p>Forgot password?</p>
                  </Link>
                </Button>
                <Button className="ButtonComponent2" variant="contained">
                  <Link className="link" to={"/signup"}>
                    <p>Sign up!</p>
                  </Link>
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default Login;
